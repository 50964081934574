import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Home: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg className={clss} width="22" height="22" viewBox="0 0 22 22" focusable="false" aria-hidden="true">
            <path d="M1.667 8.333L10 0l8.333 8.333h-2.272v8.334H3.939V8.333H1.667z" strokeWidth="2" stroke={color} fill="none" fillRule="evenodd" />
        </svg>
    );
};

export default Home;
